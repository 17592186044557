<template>
  <gl-modal
    v-bind="$attrs"
    class="pp-modal"
    full-buttons
    submit-title="Archive"
    title="Archive Case"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="pp-modal_text">
      Are you sure you want to archive <strong class="break-word">{{ title }}</strong> case?
      <div class="pp-modal_text-note">
        The archive file can be retrieved.
      </div>
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    }
  },
  methods: {
    onSubmit() {
      this.$emit('archive', this.id)
      this.$emit('input', false)
    },
  },
}
</script>
