<template>
  <gl-modal
    v-bind="$attrs"
    class="pp-modal"
    submit-title="Archive"
    title="Archive selected Cases"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="pp-modal_text">
      Are you sure you want to archive the selected cases? <br>
      <div class="pp-modal_text-note">
        The archive files can be retrieved
      </div>
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  methods: {
    onSubmit() {
      this.$emit('archive')
      this.$emit('input', false)
    },
  },
}
</script>
