<template>
  <div class="pp-my-cases__component">
    <MyCasesHeader
      :active-tab="activeTab"
      :selected-ids="selectedIds"
      @emitedData="addData($event)"
      @openRemoveModal="openRemoveModal({}, $event)"
      @reopenSelectedCase="reopenSelectedCase"
      @selectDeselectAll="selectDeselectAll"
      @showRemoveSelectedModal="showRemoveSelectedModal = true"
    />
    <div
      v-show="loading"
      class="pp-my-cases__loader"
      style="z-index: 10"
    >
      <gl-loader />
    </div>
    <div class="pp-my-cases__content">
      <div class="pp-my-cases__content-wrap">
        <MyCasesItem 
          v-for="(item, index) in cases"
          :key="index"
          :active-tab="activeTab"
          :context-menu-switcher="contextMenuSwitcher"
          :item="item"
          @closeContextMenu="closeContextMenu(item)"
          @openEditModal="openEditModal(item)"
          @openRemoveModal="openRemoveModal(item)"
          @reopenCase="reopenCase(item)"
          @updateSelected="updateSelected(item)"
        />
      </div>
    </div>
    <div class="pp-pagination-table">
      <div class="pp-pagination-table__left">
        <div class="pp-pagination-table__text">
          Rows per page:
        </div>
        <div class="pp-pagination-table__select">
          <vSelect
            v-model="perPage"
            class="select--top-direction"
            :clearable="false"
            :options="pagesOptions"
            :searchable="false"
            @input="countChange"
          />
        </div>
      </div>
      <div class="pp-pagination-table__right">
        <o-pagination
          v-if="cases.length && totalPages > 1"
          :current.sync="currentPage"
          :per-page="perPage"
          :range-after="0"
          :range-before="0"
          :total="total"
          @change="pageChange"
        >
          <template #default="props">
            <div
              v-if="props.page.isCurrent"
              class="pp-pagination-table__pages"
            >
              {{ `${props.page.number} of ${totalPages}` }}
            </div>
          </template>
          <template #previous="props">
            <div class="pp-pagination-table__button-prev">
              <o-pagination-button
                :page="props.page"
              >
                <gl-menu-item
                  :disabled="props.page.disabled"
                  icon="left"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>
              <div class="pp-pagination-table__current">
                {{ props.page.number + 1 }}
              </div>
            </div>
          </template>
          <template #next="props">
            <div class="pp-pagination-table__button-next">
              <o-pagination-button
                :page="props.page"
              >
                <gl-menu-item
                  :disabled="props.page.disabled"
                  icon="right"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>
            </div>
          </template>
        </o-pagination>
      </div>
    </div>
    <RemoveSelectedCasesModal
      v-model="showRemoveSelectedModal"
      @close="showRemoveSelectedModal = false"
      @remove="removeCase(selectedIds.join(','))"
    />
    <RemoveCaseModal
      :id="removingCase._id"
      v-model="showRemoveModal"
      :title="removingCase.title"
      @close="showRemoveModal = false"
      @remove="removeCase"
    />
    <RenameCaseModal
      v-model="showRenameModal"
      :title="updatingCase.title"
      @close="showRenameModal = false"
      @update="updateCase"
    />
  </div>
</template>

<script>
//Component
import GlMenuItem from '@/components/gl-menu-item'
import vSelect from 'vue-select'
import GlLoader from '@/components/gl-loader'
import RenameCaseModal from '../modals/RenameCaseModal'
import RemoveSelectedCasesModal from "@/pages/cases/modals/RemoveSelectedCasesModal"
import MyCasesItem from '@/pages/cases/components/MyCasesItem'
import MyCasesHeader from '@/pages/cases/components/MyCasesHeader'
// Utils
import { formatDate } from "@/utils/format-date";
import { scrollToSelector } from '@/utils/scroll-to'
// Vuex
import { mapMutations, mapActions, mapState } from 'vuex'
import {validate} from "vee-validate";
import RemoveCaseModal from "@/pages/cases/modals/RemoveCaseModal";
import moment from "moment";
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  name: 'ActiveCases',
  components:{
    vSelect,
    GlLoader,
    GlMenuItem,
    RemoveCaseModal,
    RenameCaseModal,
    RemoveSelectedCasesModal,
    MyCasesItem,
    MyCasesHeader,
  },
  mixins: [deviceWidthMixin],
  props: {
    activeTab: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showRemoveModal: false,
      showRemoveSelectedModal: false,
      showRenameModal: false,
      removingCase: {},
      updatingCase: {},
      loading: false,
      cases: [],
      pagesOptions: [6, 12, 24, 48, 96],
      currentPage: 1,
      perPage: 12,
      totalPages: 1,
      total: 1,
      selectedIds: [],
      search: '',
      transactionsListDates: [],
      showTimePanel: false,
      showTimeRangePanel: false,
      from: null,
      to: null,
      contextMenuSwitcher: '',
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
  },
  watch: {
    activeTab: {
      handler(tab) {
        if (tab === 'archive') {
          this.loadData()
        }
      },
      immediate: true
    }
  },
  mounted() {
    const { from, to } = this.$route.query

    if (from && to) {
      this.from = from
      this.to = to
      this.transactionsListDates = [new Date(String(moment.unix(from / 1000).format('YYYY-MM-DD HH:mm:ss'))), new Date(String(moment.unix(to / 1000).format('YYYY-MM-DD')))]
    }

    this.loadData()
  },
  methods: {
    moment,
    scrollToSelector,
    ...mapMutations({
      SET_GRAPH_FROM_CASE: 'cases/SET_GRAPH_FROM_CASE',
    }),
    ...mapActions('cases', ['editCase', 'deleteCase', 'casesToArchive', 'createCase', 'getArchiveCases']),
    openCopyCase(copiedCase) {
      this.createCase({
        blockchain: copiedCase.blockchain,
        search: copiedCase.search,
        graph: copiedCase.graph,
        title: `Copy of ${copiedCase.title}`,
        image: copiedCase.image
      }).then(({ success, data }) => {
        if (success) {
          this.$toasted.global.success({ message: `Case copied successfully` })
          this.openCase(data)
        } else {
          this.$toasted.global.error({ message: data.message })
        }
      })
    },
    selectDeselectAll() {
      if(this.selectedIds.length) {
        this.selectedIds = []
        this.cases.forEach(item => item.selected = false)
      } else {
        this.selectedIds = this.cases.map(item => item._id)
        this.cases.forEach(item => item.selected = true)
      }
    },
    loadData() {
      this.loading = true

      const searchingData = {
        from: this.from,
        to: this.to,
        title: this.search,
        count: this.perPage,
        skip: (this.currentPage - 1) * this.perPage,
      }

      this.getArchiveCases({
        from: searchingData && searchingData.from ? searchingData.from : undefined,
        to: searchingData && searchingData.to? searchingData.to : undefined,
        title: searchingData && searchingData.title ? searchingData.title : undefined,
        count: searchingData && searchingData.count ? searchingData.count : 6,
        skip: searchingData && searchingData.skip ? searchingData.skip : 0,
        coin: this.coinData.key.toUpperCase()
      })
        .then(({ data: { items, totalItems } }) => {
          this.total = totalItems
          this.totalPages = Math.ceil(this.total / this.perPage)
          items.forEach(item => item.selected = false)
          this.cases = items.filter(item => item.archived)
          this.loading = false
        })

      this.$nextTick(() => {
        this.selectedIds = []
      })

      this.$router.replace({ name: 'cases', query: { tab: 'archive', from: this.from, to: this.to } })
    },
    countChange() {
      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
      this.scrollToSelectorItem('.page-wrapper', '.container', 200)
    },
    removeCase(id) {
      this.deleteCase({ caseIds: id }).then(({ success }) => {
        if (success) {
          this.loadData()
          this.selectedIds = []
        }
      })
    },
    updateCase(title) {
      this.editCase({ id: this.updatingCase._id, title }).then(({ success }) => {
        if (success) {
          this.loadData()
          this.showRenameModal = false
        }
      })
    },
    openRemoveModal(item, id) {
      if(id) {
        this.removingCase = this.cases.find(caseObj => caseObj._id === id)
      } else if(item) {
        this.removingCase = item
      }
      if(this.removingCase) {
        this.showRemoveModal = true
      }
    },
    updateSelected({ _id }) {
      const caseItem = this.cases.find(item => item._id === _id)

      if (this.isSelected(_id)) {
        const index = this.selectedIds.findIndex(id => id === _id)
        this.selectedIds.splice(index, 1)
        caseItem.selected = false
      } else {
        this.selectedIds.push(_id)
        caseItem.selected = true
      }
    },
    reopenSelectedCase(){
      const ids = this.selectedIds.join(',')

      this.casesToArchive({ ids, status: false }).then(({ success }) => {
        if (success) {
          this.loadData()
          this.selectedIds = []
        }
      })
    },
    reopenCase({ _id }){
      this.casesToArchive({ ids: _id, status: false }).then(({ success }) => {
        if (success) {
          this.loadData()
          this.selectedIds = []
        }
      })
    },
    isSelected(_id) {
      return this.selectedIds.find(id => String(id) === String(_id))
    },
    openEditModal(item) {
      this.updatingCase = item
      this.showRenameModal = true
    },
    formatDate,
    async openCase(itemCase) {
      const hashValidationResult = await validate(itemCase.search, 'txHash', {name: 'Search value'})
      const addressValidationResult = await validate(itemCase.search, 'address:btc', { name: 'Search value' })

      if (hashValidationResult.valid) {
        itemCase = {
          ...itemCase,
          type: 'tx'
        }
      } else if (addressValidationResult.valid) {
        itemCase = {
          ...itemCase,
          type: 'address'
        }
      }

      this.SET_GRAPH_FROM_CASE(itemCase)
      this.$router.push({ name: 'analytics', query: { [itemCase.type]: itemCase.search, caseId: itemCase._id  }, params: { openCase: 'true', caseData: itemCase }})
    },
    scrollToSelectorItem(wrap, aim, offset) {
      this.scrollToSelector(
        wrap,
        aim,
        offset,
      )
    },
    closeContextMenu({ _id }) {
      this.contextMenuSwitcher = _id
    },
    addData({ search, transactionsListDates, from, to }) {
      this.search = search
      this.transactionsListDates = transactionsListDates
      this.from = from
      this.to = to
      this.loadData()
    },
  }
}
</script>
