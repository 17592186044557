<template>
  <div class="pp-my-cases__component">
    <MyCasesHeader
      :active-tab="activeTab"
      :selected-ids="selectedIds"
      @emitedData="addData($event)"
      @openArchiveCaseConfirmModal="openArchiveCaseConfirmModal({}, $event)"
      @openArchiveConfirmModal="openArchiveConfirmModal"
      @selectDeselectAll="selectDeselectAll"
    />
    <div
      v-show="loading"
      class="pp-my-cases__loader"
      style="z-index: 10"
    >
      <gl-loader />
    </div>
    <div class="pp-my-cases__content">
      <div class="pp-my-cases__content-wrap">
        <MyCasesItem 
          v-for="(item, index) in cases"
          :key="index"
          :active-tab="activeTab"
          :context-menu-switcher="contextMenuSwitcher"
          :item="item"
          @closeContextMenu="closeContextMenu(item)"
          @openArchiveCaseConfirmModal="openArchiveCaseConfirmModal(item)"
          @openCase="openCase(item)"
          @openCopyCase="openCopyCase(item)"
          @openEditModal="openEditModal(item)"
          @updateSelected="updateSelected(item)"
        />
      </div>
    </div>
    <div class="pp-pagination-table">
      <div class="pp-pagination-table__left">
        <div class="pp-pagination-table__text">
          Rows per page:
        </div>
        <div class="pp-pagination-table__select">
          <vSelect
            v-model="perPage"
            class="select--top-direction"
            :clearable="false"
            :options="pagesOptions"
            :searchable="false"
            @input="countChange"
          />
        </div>
      </div>
      <div class="pp-pagination-table__right">
        <o-pagination
          v-if="cases.length && totalPages > 1"
          :current.sync="currentPage"
          :per-page="perPage"
          :range-after="0"
          :range-before="0"
          :total="total"
          @change="pageChange"
        >
          <template #default="props">
            <div
              v-if="props.page.isCurrent"
              class="pp-pagination-table__pages"
            >
              {{ `${props.page.number} of ${totalPages}` }}
            </div>
          </template>
          <template #previous="props">
            <div class="pp-pagination-table__button-prev">
              <o-pagination-button
                :page="props.page"
              >
                <gl-menu-item
                  :disabled="props.page.disabled"
                  icon="left"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>
              <div class="pp-pagination-table__current">
                {{ props.page.number + 1 }}
              </div>
            </div>
          </template>
          <template #next="props">
            <div class="pp-pagination-table__button-next">
              <o-pagination-button
                :page="props.page"
              >
                <gl-menu-item
                  :disabled="props.page.disabled"
                  icon="right"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>
            </div>
          </template>
        </o-pagination>
      </div>
    </div>
    <ArchiveSelectedModal
      v-model="showArchiveModal"
      @archive="archiveAll"
      @close="showArchiveModal = false"
    />
    <ArchiveCaseModal
      :id="archivingCase._id"
      v-model="showArchiveCaseModal"
      :title="archivingCase.title"
      @archive="archiveCase"
      @close="showArchiveCaseModal = false"
    />
    <RenameCaseModal
      v-model="showRenameModal"
      :title="updatingCase.title"
      @close="showRenameModal = false"
      @update="updateCase"
    />
  </div>
</template>

<script>
//Component
import GlMenuItem from '@/components/gl-menu-item'
import vSelect from 'vue-select'
import GlLoader from '@/components/gl-loader'
import RenameCaseModal from '../modals/RenameCaseModal'
import ArchiveSelectedModal from '../modals/ArchiveSelectedModal'
import ArchiveCaseModal from '@/pages/cases/modals/ArchiveCaseModal'
import MyCasesItem from '@/pages/cases/components/MyCasesItem'
import MyCasesHeader from '@/pages/cases/components/MyCasesHeader'
// Utils
import { formatDate } from "@/utils/format-date";
import { scrollToSelector } from '@/utils/scroll-to'
// Vuex
import {mapMutations, mapActions, mapState} from 'vuex'
import {validate} from "vee-validate";
import moment from "moment";
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  name: 'ActiveCases',
  components:{
    vSelect,
    GlLoader,
    GlMenuItem,
    RenameCaseModal,
    ArchiveSelectedModal,
    ArchiveCaseModal,
    MyCasesItem,
    MyCasesHeader,
  },
  mixins: [deviceWidthMixin],
  props: {
    activeTab: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showRenameModal: false,
      showArchiveModal: false,
      showArchiveCaseModal: false,
      updatingCase: {},
      archivingCase: {},
      selectedIds: [],
      loading: false,
      cases: [],
      pagesOptions: [6, 12, 24, 48, 96],
      currentPage: 1,
      perPage: 12,
      totalPages: 1,
      total: 1,
      search: '',
      transactionsListDates: [],
      from: null,
      to: null,
      contextMenuSwitcher: '',
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'currencyList', 'coinData']),
  },
  watch: {
    activeTab: {
      handler(tab) {
        if (tab === 'active-cases') {
          this.loadData()
        }
      },
      immediate: true
    }
  },
  mounted() {
    const { from, to } = this.$route.query

    if (from && to) {
      this.from = from
      this.to = to
      this.transactionsListDates = [new Date(String(moment.unix(from / 1000).format('YYYY-MM-DD HH:mm:ss'))), new Date(String(moment.unix(to / 1000).format('YYYY-MM-DD')))]
    }

    this.loadData()
  },
  methods: {
    moment,
    scrollToSelector,
    ...mapMutations({
      SET_GRAPH_FROM_CASE: 'cases/SET_GRAPH_FROM_CASE',
      SET_COIN_TYPE: 'analytics/SET_COIN_TYPE',
      SET_COIN_DATA: 'analytics/SET_COIN_DATA'
    }),
    ...mapActions('cases', ['editCase', 'casesToArchive','createCase', 'getCaseById', 'getActiveCases']),
    openArchiveConfirmModal() {
      this.showArchiveModal = true
    },
    openArchiveCaseConfirmModal(item, id) {
      if(id) {
        this.archivingCase = this.cases.find(caseObj => caseObj._id === id)
      } else if(item) {
        this.archivingCase = item
      }
      if(this.archivingCase) {
        this.showArchiveCaseModal = true
      }
    },
    openCopyCase(copiedCase) {
      this.createCase({
        blockchain: copiedCase.blockchain,
        search: copiedCase.search,
        graph: copiedCase.graph,
        title: `Copy of ${copiedCase.title}`,
        image: copiedCase.image
      }).then(({ success, data: { insertedId, message } }) => {
        if (success) {
          this.$toasted.global.success({ message: `Case copied successfully` })
          this.getCaseById(insertedId).then(({ data }) => {
            if (data._id) {
              this.openCase(data)
            } else {
              this.loadData()
            }
          })
        } else {
          this.$toasted.global.error({ message })
        }
      })
    },
    loadData() {
      this.loading = true

      const searchingData = {
        from: this.from,
        to: this.to,
        title: this.search,
        count: this.perPage,
        skip: (this.currentPage - 1) * this.perPage,
      }

      this.getActiveCases({
        from: searchingData && searchingData.from ? searchingData.from : undefined,
        to: searchingData && searchingData.to? searchingData.to : undefined,
        title: searchingData && searchingData.title ? searchingData.title : undefined,
        count: searchingData && searchingData.count ? searchingData.count : 6,
        skip: searchingData && searchingData.skip ? searchingData.skip : 0,
        coin: this.coinData.key.toUpperCase()
      })
        .then(({ data: { items, totalItems } }) => {
          this.total = totalItems
          this.totalPages = Math.ceil(this.total / this.perPage)
          items.forEach(item => item.selected = false)
          this.cases = items.filter(item => !item.archived)
          this.loading = false
        }).finally(() => {
          this.loading = false
      })

      this.$nextTick(() => {
        this.selectedIds = []
      })

      this.$router.replace({ name: 'cases', query: { tab: 'active-cases', from: this.from, to: this.to } })
    },
    selectDeselectAll() {
      if(this.selectedIds.length) {
        this.selectedIds = []
        this.cases.forEach(item => item.selected = false)
      } else {
        this.selectedIds = this.cases.map(item => item._id)
        this.cases.forEach(item => item.selected = true)
      }
    },
    updateCase(title) {
      this.editCase({ id: this.updatingCase._id, title }).then(({ success, data }) => {
        if (success) {
          this.getCaseById(this.updatingCase._id).then(() => {
            this.loadData()
            this.showRenameModal = false
          })
        } else {
          this.$toasted.global.error({ message: data.message })
        }
      })
    },
    updateSelected({ _id }) {
      const caseItem = this.cases.find(item => item._id === _id)

      if (this.isSelected(_id)) {
        const index = this.selectedIds.findIndex(id => id === _id)
        this.selectedIds.splice(index, 1)
        caseItem.selected = false
      } else {
        this.selectedIds.push(_id)
        caseItem.selected = true
      }
    },
    archiveAll() {
      const ids = this.selectedIds.join(',')

      this.casesToArchive({ ids, status: true }).then(({ success }) => {
        if (success) {
          this.loadData()
          this.selectedIds = []
        }
      })
    },
    archiveCase(ids) {
      this.casesToArchive({ ids, status: true }).then(({ success }) => {
        if (success) {
          this.loadData()
          this.selectedIds = []
        }
      })
    },
    isSelected(_id) {
      return this.selectedIds.find(id => String(id) === String(_id))
    },
    openEditModal(data) {
      this.showRenameModal = true
      this.updatingCase = data
    },
    formatDate,
    countChange() {
      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
      this.scrollToSelectorItem('.page-wrapper', '.container', 200)
    },
    async openCase(itemCase) {
      const insertCoinData = this.currencyList.find(coin => coin.label === itemCase.blockchain) || null
      this.SET_COIN_DATA(insertCoinData)

      const hashValidationResult = await validate(itemCase.search, 'txHash', {name: 'Search value'})
      const addressValidationResult = await validate(itemCase.search, `address:${insertCoinData.key}`, { name: 'Search value' })

      if (hashValidationResult.valid) {

        itemCase = {
          ...itemCase,
          type: 'tx'
        }
      } else if (addressValidationResult.valid) {
        itemCase = {
          ...itemCase,
          type: 'address'
        }
      }

      this.SET_COIN_TYPE(insertCoinData.key)
      this.SET_COIN_DATA(insertCoinData)

      this.SET_GRAPH_FROM_CASE(itemCase)
      localStorage.setItem('caseId', itemCase._id)
      await this.$router.push({ name: 'analytics', query: { type: insertCoinData.key, [itemCase.type]: itemCase.search, caseId: itemCase._id  }})
    },
    scrollToSelectorItem(wrap, aim, offset) {
      this.scrollToSelector(
        wrap,
        aim,
        offset,
      )
    },
    closeContextMenu({ _id }) {
      this.contextMenuSwitcher = _id
    },
    addData({ search, transactionsListDates, from, to }) {
      this.search = search
      this.transactionsListDates = transactionsListDates
      this.from = from
      this.to = to
      this.loadData()
    },
  }
}
</script>