<template>
  <gl-modal
    v-bind="$attrs"
    class="pp-modal"
    submit-title="Delete"
    title="Delete Cases"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="pp-modal_text">
      Are you sure you want to delete the selected cases?
      <div class="pp-modal_text-note pp-modal_text-note--bold">
        This action cannot be undone.
      </div>
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  methods: {
    onSubmit() {
      this.$emit('remove')
      this.$emit('input', false)
    },
  },
}
</script>
